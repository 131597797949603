import { Reveal } from "../../../animation/reveal"

export const SponsorGrid = ({ children }) => {

    return (

        <div className={`block`}>
            <div className='bl-1 bc-primary pl-md'>
                <Reveal>
                    <p className='fs-1 fw-700 lh-1 c-primary'>2025 Sponsors</p>
                </Reveal>

                <div className={`flex gap-sm flex-wrap mt-md`}>
                    { children }
                </div>
            </div>
        </div>

    )
}