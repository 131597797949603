import Image from 'next/image'

import { Reveal } from '../../animation/reveal'
import { Pop, Fade, Slide } from '../../animation/animation'

import { Button } from '../../components/button/button'

import CSS from './hero-brand.module.css'

export function HeroBrand ({ children, theme }) {
    return (
        
        <section className={`${CSS.layout} ${ theme ? theme : `bg-primary`}`} >

            <div className={CSS.heroImage}>
                <Image src={`/assets/img/homepage/hero-2025-b.jpg`} layout={`fill`} width={1920} height={1000} alt="" priority />
            </div>

            <div className={`${CSS.panel} block`}>
                <div className={CSS.brand}>
                    <Reveal>FUTURE</Reveal> 
                    <Reveal delay={.1}>HOSPITALITY </Reveal>
                    <Reveal delay={.2}>SUMMIT <span>AFRICA</span></Reveal>
                </div>
                <Pop inline><p className='fs-4 fw-600 mt-xxs'>Formerly 'AHIF'</p></Pop>
                
                <div className="mt-md">
                    <Slide delay={.6}>
                        <p className='fs-4 fw-600'>17-19 June 2025</p>
                        <p className='fs-4'>The Westin Cape Town, South Africa</p>
                    </Slide>
                </div>
                <div className='mt-sm'>
                    <Pop inline>
                        <Button href={'https://www.eventbooking.uk.com/AHIF-2025?_ga=2.137623475.2013761134.1731343042-747783354.1720527023'} decal={'fs-6 fw-600'}>Purchase Pass</Button>
                    </Pop>
                </div>
            </div>

            <section className={CSS.content}>
                <div className={CSS.theme}>
                    <Reveal>Connect</Reveal> 
                    <Reveal delay={.1}>Invest</Reveal> 
                    <Reveal delay={.2}>Transform</Reveal>
                </div>
                <Fade>
                    <p className="fs-5">Future Hospitality Summit Africa (formerly AHIF) is the leading hospitality investment forum on the African continent. The event connects business leaders to enable deals that drive investment and development into tourism and hotel infrastructure.</p>
                </Fade>
                <div className="mt-sm button-grid">
                    <Pop inline>
                        <Button href={'/partners'} outline decal={'fw-600'}>Become a Sponsor</Button>
                    </Pop>
                    <Pop inline>
                        <Button href={'/download-prospectus'} outline decal={'fw-600'}>Download Prospectus</Button> 
                    </Pop>
                </div>
            </section>

            <section className={CSS.graphic}>
                <Image src={`/assets/img/homepage/brand-pattern-horizontal.jpg`} layout={`fill`} width={1920} height={1000} alt="" priority />
            </section>

        </section>

    )
}